import React, { useEffect, useState } from 'react';
import { AgmQuestion } from './AgmQuestion';
import { AgmVoted } from './AgmVoted';
import { AgmGroupResults } from './AgmGroupResults';
import { IAgmGroupResultView, IAgmGroupStatusView, IAgmGroupView, IBallotView } from '../../types/portalTypes';

export interface AgmResultsProps {
    ballot: IBallotView
    status: IAgmGroupStatusView
    agmGroups: IAgmGroupView[]
    setError?: any
}

export const AgmResults: React.FC<AgmResultsProps> = ({ ballot, status, agmGroups, setError }) => {
    const [groupResults, setGroupResults] = useState<IAgmGroupResultView>()
    const [selectedGroup, setSelectedGroup] = useState<IAgmGroupView>()

    useEffect(() => {
        if (ballot) {
            document.title = "AGM Results - " + ballot.ballotName;
        }
    }, [ballot])

    useEffect(() => {
        // Default to open group
        if (status.openGroup) {
            setSelectedGroup(status.openGroup)
        }
        // Show last closed if none open
        else if (status.lastClosedGroupId) {
            setSelectedGroup(getGroup(agmGroups, status.lastClosedGroupId))
        }
        // Default to first group if all other conditions false
        else if (agmGroups[0]) {
            setSelectedGroup(getGroup(agmGroups, agmGroups[0].groupId))
        }
    }, [status, agmGroups])

    const getGroup = (groups: IAgmGroupView[], groupId: number): IAgmGroupView | undefined => {
        return groups.find(g => g.groupId === groupId)
    }

    const onFetchResults = (results: IAgmGroupResultView): void => {
        setGroupResults(results)
    }

    const onGroupSelected = (groupId: number): void => {
        setSelectedGroup(getGroup(agmGroups, groupId))
    }

    const panelCss = "bg-white py-8 px-10 shadow sm:rounded-lg"

    return (
        <>
            {status && selectedGroup &&
                <>
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div className="col-span-2">
                            <AgmQuestion
                                question={selectedGroup.groupName}
                                status={status}
                                groups={agmGroups}
                                selectedGroup={selectedGroup}
                                onGroupSelected={onGroupSelected}
                                panelCss={panelCss}
                            />
                        </div>
                        <div className={`${panelCss} col-span-1`}>
                            <AgmVoted groupResults={groupResults} />
                        </div>
                    </div>
                    <div className={`${panelCss}`}>
                        <AgmGroupResults
                            ballot={ballot}
                            groupId={selectedGroup.groupId}
                            groupResults={groupResults}
                            onFetchResults={onFetchResults}
                            openGroupId={status.openGroup?.groupId} />
                    </div>
                </>
            }
        </>
    );
};