import React, { ReactNode } from 'react';
import dompurify from 'dompurify'

interface InfoBannerProps {
    isSticky?: boolean;
    isSetHtml?: boolean;
    children: ReactNode;
}

export const InfoBanner: React.FC<InfoBannerProps> = ({ isSticky, isSetHtml, children }) => {
    const sanitizer = dompurify.sanitize;
    const css = `p-2 rounded-lg shadow-lg bg-blue-600 block w-full text-white font-medium text-center mb-4 ${isSticky ? "sticky top-2" : ""}`

    return (
        <React.Fragment>
            {isSetHtml && typeof children === 'string' &&
                <div className={css} dangerouslySetInnerHTML={{ __html: sanitizer(children) }} />
            }
            {
                !isSetHtml &&
                <div className={css}>
                    {children}
                </div>
            }
        </React.Fragment>
    );
}