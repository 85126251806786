import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ExtendedColumn, Table } from '../table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IAgmGroupResultView, IBallotView, IOptionResult, PortalApiRoutes } from '../../types/portalTypes';

interface AgmGroupResultsProps {
    ballot: IBallotView
    groupId: number
    groupResults?: IAgmGroupResultView
    onFetchResults: any
    openGroupId: number
}

export const AgmGroupResults: React.FC<AgmGroupResultsProps> = ({ ballot, groupId, groupResults, onFetchResults, openGroupId }) => {
    // An incrementing prop that can be used to trigger a refresh by the table
    const [fetchDataTrigger, setFetchDataTrigger] = useState(0)

    const tableColumns: ExtendedColumn<IOptionResult>[] = [
        { Header: "Option", accessor: "optionName", textAlign: "left", canSort: false },
        { Header: "Result", accessor: "score", width: "100px", canSort: false },
        {
            Header: "% Voters Responded", accessor: "percentageResponded", width: "100px", Cell: ({ value }: { value: number }) => (
                <span>{(value * 100).toFixed(2)}%</span>
            ),
            canSort: false
        },
        {
            Header: "% Total Voters", accessor: "percentageTotalVoters", width: "100px", Cell: ({ value }: { value: number }) => (
                <span>{(value * 100).toFixed(2)}%</span>
            ),
            canSort: false
        },
    ]

    const fetchResults = async (pageSize: number, pageIndex: number, sortColumns: Array<any>): Promise<any> => {
        return await axios.post(PortalApiRoutes.AGM_RESULTS,
            {
                ballotId: ballot.ballotId,
                groupId,
                pageSize,
                pageIndex,
                sortColumns
            }).catch((err) => console.log(err))
    }

    // Refresh every 5 seconds if group is open
    useEffect(() => {
        if (groupId === openGroupId) {
            const interval = setInterval(async () => {
                setFetchDataTrigger(prevTrigger => prevTrigger + 1)
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [groupId, openGroupId]);

    return (
        <div>
            {groupResults?.groupReportDownloadUrl &&
                <a className="btn" href={groupResults?.groupReportDownloadUrl}>
                    <FontAwesomeIcon className="mr-2" icon={faDownload} />
                    Download Results
                </a>
            }
            <Table
                fetchDataAsync={fetchResults}
                columns={tableColumns}
                onFetchData={onFetchResults}
                dataId={groupId}
                fetchDataTrigger={fetchDataTrigger}
            />
        </div>
    );
}