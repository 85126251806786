import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import App from './App';
import { Login } from './components/account/Login';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';
import { AgmApp } from './components/agm/AgmApp';
import { ErrorPage } from './components/error/ErrorPage';
import './tailwind.css'
import './custom.css'
import { PortalRoutes } from './types/portalTypes';
import { VoterAttendance } from './components/ballot/voter/VoterAttendance';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Add a response interceptor for status codes
axios.interceptors.response.use(function (response) {
    // If the response is successful, just return it
    return response;
}, function (error) {
    // If the response had a 401, redirect to login page (provided not already on that page)
    if (error.response && error.response.status === 401 && window.location.href !== PortalRoutes.ACCOUNT_LOGIN) {
        window.location.href = PortalRoutes.ACCOUNT_LOGIN;
        return new Promise(() => { });
    }

    // If the response had a 403, redirect to not authorised page
    if (error.response && error.response.status === 403) {
        window.location.href = PortalRoutes.NOT_AUTHORISED;
        return new Promise(() => { });
    }

    // If the response had a different error, just throw it
    return Promise.reject(error);
});

export const useQueryParam = (param) => {
    const location = useLocation();
    const [value, setValue] = useState(new URLSearchParams(location.search).get(param));

    useEffect(() => {
        setValue(new URLSearchParams(location.search).get(param));
    }, [location, param]);

    return value;
};

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Switch>
            <Route exact path={PortalRoutes.ACCOUNT_LOGIN} component={Login} />
            <Route exact path={PortalRoutes.NOT_AUTHORISED} component={() => <ErrorPage type="not-authorised" />} />
            <Route exact path={PortalRoutes.AGM_ADMIN} render={() => <AgmApp type="admin" />} />
            <Route exact path={PortalRoutes.AGM_RESULTS} render={() => <AgmApp type="results" />} />
            <Route exact path={PortalRoutes.VOTER_ATTENDANCE} render={() => <VoterAttendance />} />
            <Route path="*" component={App} />
        </Switch>
    </BrowserRouter>,
    rootElement);

registerServiceWorker();