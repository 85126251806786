import React, { useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '../common/LoadingButton';
import { useHistory } from 'react-router-dom';
import { ErrorBanner } from '../common/ErrorBanner';
import { PortalApiRoutes } from '../../types/portalTypes';

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [twoFactorCode, setTwoFactorCode] = useState("");
    const [userId, setUserId] = useState(null);
    const [isLoginSubmitting, setLoginSubmitting] = useState(false)
    const [isTwoFactorSubmitting, setTwoFactorSubmitting] = useState(false)
    const [isRequireTwoFactor, setRequireTwoFactor] = useState(false)
    const [authError, setAuthError] = useState(null)
    const history = useHistory();

    const handleSubmitLogin = async (event) => {
        setLoginSubmitting(true)
        try {
            const response = await axios.post(PortalApiRoutes.ACCOUNT_LOGIN, { username, password });
            if (response.status === 200) {
                if (response.data?.requiresTwoFactor === true) {
                    // Show code
                    setUserId(response.data.userId)
                    setRequireTwoFactor(true)
                }
                else {
                    // Authentication successful
                    history.push('/')
                }
            }
            else {
                setAuthError("Invalid username or password")
            }
        } catch (error) {
            setAuthError(error.response.data.message);
        }
        finally {
            setLoginSubmitting(false)
        }
    }

    const handleSubmitTwoFactor = async (event) => {
        setTwoFactorSubmitting(true)
        try {
            const response = await axios.post(PortalApiRoutes.ACCOUNT_TWO_FACTOR, { userId, twoFactorCode });
            if (response.status === 200) {
                // Authentication successful
                history.push('/')
            }
            else {
                setAuthError("Invalid two-factor code")
            }
        } catch (error) {
            setAuthError(error.response.data.message);
        }
        finally {
            setTwoFactorSubmitting(false)
        }
    };

    return (
        <div className="/*min-h-screen*/ justify-center py-12">
            <div className="mx-auto max-w-lg">
                <div className="bg-white py-8 px-10 shadow rounded-lg">
                    <form>
                        <div className="text-center mb-10">
                            <img src="/img/logo.png" className="mx-auto mb-8 max-h-24" title="TrueVote" alt="TrueVote" />
                            <p className="mb-4 text-3xl font-bold">Welcome to TrueVote</p>
                        </div>

                        {authError && <ErrorBanner>{authError}</ErrorBanner>}

                        {isRequireTwoFactor ?
                            <>
                                <span className="mb-4 block text-center">Enter the code sent to your email address</span>

                                <div className="mb-4">
                                    <label htmlFor="code" className="block font-medium mb-2">Code</label>
                                    <input id="code" type="text" onChange={(e) => setTwoFactorCode(e.target.value)} value={twoFactorCode} required />
                                </div>

                                <div className="mt-8">
                                    <LoadingButton className="btn w-full block"
                                        btnText="Login"
                                        isLoading={isTwoFactorSubmitting}
                                        onBtnClick={handleSubmitTwoFactor}
                                        isSubmit="true" />
                                </div>
                            </>
                            :
                            <>
                                <div className="mb-4">
                                    <label htmlFor="username" className="block font-medium mb-2">Username</label>
                                    <input id="username" type="text" onChange={(e) => setUsername(e.target.value)} value={username} required />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="password" className="block font-medium mb-2">Password</label>
                                    <input id="password" type="password" onChange={(e) => setPassword(e.target.value)} value={password} required />
                                </div>

                                <div className="mt-8">
                                    <LoadingButton className="btn w-full block"
                                        btnText="Login"
                                        isLoading={isLoginSubmitting}
                                        onBtnClick={handleSubmitLogin}
                                        isSubmit="true" />
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};