import React, { useEffect, useState } from 'react';

import { BallotsTimeline } from './components/admin/BallotsTimeline';
import { Switch, Route } from 'react-router';
import axios from 'axios';
import { Ballots } from './components/admin/Ballots';
import { Sidebar } from './components/portal/Sidebar';
import { RoutePage } from './components/portal/RoutePage';
import { LoadingPanel } from './components/common/LoadingPanel';
import { IUserMenu, PortalApiRoutes, PortalRoutes } from './types/portalTypes';

const App: React.FC = () => {
    const [isPageInit, setPageInit] = useState<boolean>(false)
    const [userMenu, setUserMenu] = useState<IUserMenu>()

    useEffect(() => {
        if (isPageInit) {
            return
        }

        // Retrieve user info (if not logged in the 403 will auto redirect to login page on first load)
        axios.get(PortalApiRoutes.USER_INFO)
            .then((response) => {
                setUserMenu(response.data as IUserMenu)
                setPageInit(true)
            })
            .catch((error: any) => {
                alert(error);
            })
    }, [isPageInit])

    return (
        <>
            {isPageInit ?
                <div className="flex">
                    <Sidebar userMenu={userMenu} onLoggedOut={() => setPageInit(false)} />
                    <div className="flex-1 p-4">
                        <Switch>
                            <RoutePage path={PortalRoutes.BALLOTS_TIMELINE} title="Ballots Timeline">
                                <BallotsTimeline legacyBaseUrl={userMenu?.legacyBaseUrl} />
                            </RoutePage>
                            <RoutePage path={PortalRoutes.BALLOTS} title="Ballots">
                                <Ballots legacyBaseUrl={userMenu?.legacyBaseUrl} />
                            </RoutePage>
                            <RoutePage path={PortalRoutes.HOME} title="Ballots">
                                <Ballots legacyBaseUrl={userMenu?.legacyBaseUrl} />
                            </RoutePage>
                        </Switch>
                    </div>
                </div>
                :
                <div className="container">
                    <LoadingPanel className="bg-white py-8 px-10 shadow sm:rounded-lg sm:mt-8 text-center"
                        isLoading={!isPageInit}
                        loadingText="Loading Portal..."
                    />
                </div>
            }
        </>
    )
}

export default App;