import React, { useState } from 'react';

interface ToggleProps {
    isOn: boolean;
    cssOnColor?: string;
    onTooltip?: string;
    offTooltip?: string;
    errorText?: string | null;
    handleToggle: () => void;
}

export const Toggle: React.FC<ToggleProps> = ({ isOn, cssOnColor = 'bg-blue-600', onTooltip, offTooltip, errorText, handleToggle }) => {
    const [showError, setShowError] = useState(false);

    const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();

        if (errorText) {
            setShowError(true);
        }
        else {
            handleToggle();
        }
    }

    return (
        <>
            <div
                title={isOn ? onTooltip : offTooltip}
                onClick={handleButtonClick}
                className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer ${isOn ? cssOnColor : 'bg-gray-300'
                    }`}
            >
                <div
                    className={`bg-white w-6 h-6 rounded-full shadow-md transform ${isOn ? 'translate-x-6' : ''
                        }`}
                ></div>
            </div>
            {showError && errorText &&
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="bg-white p-4 sm:p-6">
                                <h2 id="modal-headline" className="text-lg leading-6 font-medium">
                                    Error
                                </h2>
                                <div className="mt-2">
                                    <p>
                                        {errorText}
                                    </p>
                                </div>
                                <div className="mt-2 sm:flex sm:flex-row-reverse">
                                    <button
                                        onClick={() => setShowError(false)}
                                        type="button"
                                        className="btn ml-0 sm:ml-2">
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};