import React from 'react'
import axios from 'axios';
import { ExtendedColumn, Table } from '../table/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTrash } from '@fortawesome/free-solid-svg-icons'

import '../../tailwind.css'
import '../../custom.css'
import { IBallotView, PortalApiRoutes } from '../../types/portalTypes'
import { Row } from 'react-table'

interface BallotsProps {
    legacyBaseUrl: string | undefined
}

export const Ballots: React.FC<BallotsProps> = ({ legacyBaseUrl }) => {
    const tableColumns: ExtendedColumn<IBallotView>[] = React.useMemo(() => [
        {
            Header: "", id: "edit", disableSortBy: true, disableFilters: true, Cell: ({ row }: { row: Row<IBallotView> }) => {
                const editUrl = `${legacyBaseUrl}/AdminPortal/Ballots/Dashboard.aspx?id=${row.original.ballotId}`; // Build the dynamic URL
                return (
                    <a href={editUrl} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faCog} />
                    </a>
                );
            }
        },
        { Header: "Name", accessor: "ballotName", textAlign: "left" },
        { Header: "Organisation", accessor: "organisation" },
        { Header: "Open", accessor: "openDate", Cell: ({ value }: { value: string }) => <>{value}</> },
        { Header: "Close", accessor: "closeDate", Cell: ({ value }: { value: string }) => <>{value}</> },
        {
            Header: "", id: "actions", disableSortBy: true, disableFilters: true, Cell: ({ row }: { row: Row<IBallotView> }) => {
                return (
                    <a href={PortalApiRoutes.BALLOT_DELETE}>
                        <FontAwesomeIcon icon={faTrash} />
                    </a>
                )
            }
        },
    ], [])

    const fetchBallots = async (pageSize: number, pageIndex: number, sortColumns: Array<any>): Promise<any> => {
        return await axios.post(PortalApiRoutes.BALLOTS,
            {
                pageSize,
                pageIndex,
                sortColumns
            }).catch((err) => console.log(err))
    }

    return (
        <>
            <Table<IBallotView>
                fetchDataAsync={fetchBallots}
                columns={tableColumns}
            />
        </>
    )
}