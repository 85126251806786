import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dompurify from 'dompurify';
import { PANEL_CSS } from './AgmApp';
import { getDurationFormatted } from '../common/Countdown';
import { AgmAdminGroup } from './AgmAdminGroup';
import { PopupDialog } from '../common/dialog/PopupDialog';
import { LoadingButton } from '../common/LoadingButton';
import { InfoBanner } from '../common/InfoBanner';
import { ErrorBanner } from '../common/ErrorBanner';
import { AgmGroupStatus, AgmVoteStatus, IAgmGroupStatusView, IAgmGroupView, IAgmUpdateGroupModel, IBallotView, PortalApiRoutes } from '../../types/portalTypes';

export interface AgmAdminProps {
    ballot: IBallotView
    status: IAgmGroupStatusView
    agmGroups: IAgmGroupView[]
    setError?: any
    onStatusUpdate: any
}

interface ICreateQuestion {
    groupId?: number,
    question: string,
    description?: string,
    options: string,
    duration?: number
}

//
type IAgmCreateOrUpdateGroupModel = IAgmUpdateGroupModel | {
    groupId: number | null
}

export const AgmAdmin: React.FC<AgmAdminProps> = ({ ballot, status, agmGroups, setError, onStatusUpdate }) => {
    const [pendingGroups, setPendingGroups] = useState<IAgmGroupView[]>()
    const [closedGroups, setClosedGroups] = useState<IAgmGroupView[]>()
    const [showCreateQuestionDialog, setShowCreateQuestionDialog] = useState<boolean>(false)
    const [createQuestion, setCreateQuestion] = useState<ICreateQuestion>({
        groupId: undefined,
        question: "",
        description: undefined,
        options: "",
        duration: undefined
    })
    const [showCreateReview, setShowCreateReview] = useState(false)
    const [isCreateSubmitting, setCreateSubmitting] = useState(false)
    const [createError, setCreateError] = useState<string | null>()

    useEffect(() => {
        if (ballot) {
            document.title = "AGM Admin - " + ballot.ballotName;
        }
    }, [ballot])

    useEffect(() => {
        const openGroupId = status?.openGroup?.groupId
        const pendingGroups = agmGroups?.filter(question => question.groupId !== openGroupId && !question.closeTime)
        const closedQuestions = agmGroups?.filter(question => question.status !== AgmGroupStatus.Open && question.status !== AgmGroupStatus.Paused && question.closeTime)
        setPendingGroups(pendingGroups)
        setClosedGroups(closedQuestions)
    }, [status, agmGroups])

    const setShowCreate = (isShow: boolean, editGroup: IAgmGroupView | null = null): void => {
        if (isShow) {
            // Clear or set values to edit group
            setCreateQuestion({
                groupId: editGroup ? editGroup.groupId : undefined,
                question: editGroup ? editGroup.groupName : "",
                description: editGroup ? editGroup.groupDescription : undefined,
                options: editGroup ? editGroup.options.join('\n') : "",
                duration: editGroup ? editGroup.duration : undefined
            })
        }

        setShowCreateQuestionDialog(isShow)
    }

    const handleShowReview = () => {
        if (!createQuestion.question) {
            setCreateError("Please enter a question")
            return
        }

        if (!createQuestion.options) {
            setCreateError("Please enter question options")
            return
        }

        setCreateError(null)
        setShowCreateReview(true)
    }

    const handleCreateChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setCreateQuestion({
            ...createQuestion,
            [e.target.name]: e.target.value !== '' ? e.target.value : null
        })
    }

    const submitCreateOrUpdateQuestion = () => {
        // Check for required values
        if (!createQuestion.question || !createQuestion.options) {
            return
        }

        setCreateSubmitting(true)

        const url = createQuestion.groupId ? PortalApiRoutes.AGM_UPDATE_GROUP : PortalApiRoutes.AGM_CREATE_GROUP

        const payload: IAgmCreateOrUpdateGroupModel = {
            ballotId: ballot.ballotId,
            groupId: createQuestion.groupId ? createQuestion.groupId : null,
            question: createQuestion.question,
            description: createQuestion.description,
            options: createQuestion.options.split('\n'),
            duration: createQuestion.duration
        }

        // Submit question create
        axios.post(url, payload)
            .then((response) => {
                if (response.data.isSuccessful) {
                    setShowCreate(false)
                    setShowCreateReview(false)
                    setCreateError(null)
                    onStatusUpdate(response.data.payload)
                }
                else {
                    setCreateError(response.data.errorMessage)
                }
                setCreateSubmitting(false)
            })
            .catch(function (error) {
                alert(error)
            })
    }

    const sortGroupsByCloseTime = (a: any, b: any) => {
        const dateA = a.closeTime ? new Date(a.closeTime) : new Date(0); // Fallback to a default date if null/undefined
        const dateB = b.closeTime ? new Date(b.closeTime) : new Date(0); // Fallback to a default date if null/undefined
        return dateB.getTime() - dateA.getTime();
    };

    return (
        <>
            {status?.openGroup &&

                <div className={PANEL_CSS}>
                    <div className="flex items-center">
                        <AgmAdminGroup
                            ballotId={ballot.ballotId}
                            group={status.openGroup}
                            status={status}
                            onStatusUpdate={onStatusUpdate}
                            setError={setError}
                        />
                    </div>
                </div>
            }
            {status.status !== AgmVoteStatus.BallotComplete &&
                <div className={`${PANEL_CSS} ${status?.openGroup ? "bg-gray-200" : ""} `}>
                    <div className="flex items-center mb-4 pb-4 border-b border-gray-300">
                        <span className="text-2xl flex-auto">Pending Questions</span>
                        <button className="btn" onClick={() => setShowCreate(true)}>Create AGM Question</button>
                    </div>
                    {pendingGroups && pendingGroups.length > 0 &&
                        <div>
                            {pendingGroups.map((group, index) =>
                                <div key={group.groupId}
                                    className="flex items-center p-4 hover:bg-gray-100">
                                    <AgmAdminGroup
                                        ballotId={ballot.ballotId}
                                        group={group}
                                        status={status}
                                        onStatusUpdate={onStatusUpdate}
                                        setError={setError}
                                        onShowEdit={setShowCreate}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    <div className="flex justify-center">

                    </div>

                    <PopupDialog
                        isOpen={showCreateQuestionDialog}
                        title={createQuestion.groupId ? "Edit AGM Question" : "Create AGM Question"}>
                        <>
                            {createError &&
                                <ErrorBanner>{createError}</ErrorBanner>
                            }
                            {!showCreateReview &&
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="create-question" className="block mb-2">Question</label>
                                        <textarea id="create-question"
                                            rows={3}
                                            name="question"
                                            onChange={handleCreateChange}
                                            value={createQuestion.question != null ? createQuestion.question : ''} required />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="create-description" className="block mb-2">Description (optional)</label>
                                        <textarea id="create-description"
                                            rows={3}
                                            name="description"
                                            onChange={handleCreateChange}
                                            value={createQuestion.description != null ? createQuestion.description : ''} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="create-options" className="block mb-2">Question options (new line per option)</label>
                                        <textarea id="create-options"
                                            rows={3}
                                            name="options"
                                            onChange={handleCreateChange}
                                            value={createQuestion.options != null ? createQuestion.options : ''} required />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="create-duration" className="block mb-2">Duration in seconds (optional)</label>
                                        <input id="create-duration"
                                            type="number"
                                            name="duration"
                                            onChange={handleCreateChange}
                                            value={createQuestion.duration != null ? createQuestion.duration : ''} />
                                    </div>
                                </>
                            }
                            {showCreateReview &&
                                <>
                                    <InfoBanner>
                                        Please review your question before pressing {createQuestion.groupId ? "Update" : "Create"}
                                        {createQuestion.duration &&
                                            <p className="pt-4">Question will be open for voting for {createQuestion.duration} seconds</p>
                                        }
                                    </InfoBanner>

                                    <span className="block mb-2" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(createQuestion.question?.replace("\n", "</br>")) }} />
                                    {createQuestion.description &&
                                        <span className="block mb-2" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(createQuestion.description?.replace("\n", "</br>")) }} />
                                    }
                                    <div className="inline-flex flex-wrap">
                                        {createQuestion.options && createQuestion.options.split("\n").map((surveyOption) =>
                                            <div key={surveyOption} className="pr-4 pb-4">
                                                <input type="radio"
                                                    name="createQuestionReview"
                                                    id={surveyOption}
                                                    value={surveyOption} />
                                                <label htmlFor={surveyOption}
                                                    className="cursor-pointer disabled:cursor-default">{surveyOption}</label>
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                            <div className="sm:flex sm:flex-row-reverse">
                                {!showCreateReview &&
                                    <>
                                        <button
                                            onClick={handleShowReview}
                                            type="button"
                                            className="btn ml-0 sm:ml-2">
                                            Review
                                        </button>

                                        <button
                                            onClick={() => setShowCreate(false)}
                                            type="button"
                                            className="btn-gray ml-2 sm:ml-0">
                                            Cancel
                                        </button>
                                    </>
                                }
                                {showCreateReview &&
                                    <>
                                        <LoadingButton
                                            btnText={createQuestion.groupId ? "Update" : "Create"}
                                            isLoading={isCreateSubmitting}
                                            isSubmit={true}
                                            onBtnClick={submitCreateOrUpdateQuestion}
                                            className="btn ml-0 sm:ml-2"
                                        />
                                        <button
                                            onClick={() => setShowCreateReview(false)}
                                            type="button"
                                            className="btn-gray ml-2 sm:ml-0">
                                            Back
                                        </button>
                                    </>
                                }
                            </div>
                        </>
                    </PopupDialog>
                </div>
            }
            {closedGroups && closedGroups?.length > 0 &&
                <div className={`${PANEL_CSS} ${status?.openGroup ? "bg-gray-200" : ""} `}>
                    <span className="text-2xl block pb-4 mb-4 border-gray-300 border-b">Closed Questions</span>

                    <div>
                        {closedGroups
                            .sort(sortGroupsByCloseTime)
                            .map((question, index) =>
                                <div key={question.groupId}
                                    className="flex items-center p-4 hover:bg-gray-100">
                                    <span className="text-xl flex-auto"
                                        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.groupName.replace("\n", "</br>")) }} />
                                    <div>
                                        <span className="block text-right">Duration: {getDurationFormatted(question.duration)}</span>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            }
        </>
    );
};