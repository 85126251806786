import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'

export const LoadingPanel = ({ isLoading, className, loadingText }) => {
    return (
        <>
            {isLoading &&
                <div className={className}>
                    <div className="fa-fade">
                        {loadingText && <div className="text-4xl mb-10">{loadingText}</div>}
                        <FontAwesomeIcon icon={faClipboardList} className=" text-8xl fa-beat" />
                    </div>
                </div>
            }
        </>
    );
}