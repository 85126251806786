import React from 'react';
import { ErrorBanner } from '../common/ErrorBanner';
import { Footer } from '../common/page/Footer';

export const ErrorPage = ({ type }) => {
    if (type === "not-authorised") {
        document.title = "Not Authorised"
    }

    return (
        <div className="container sm:mt-8">
            {type === "not-authorised" &&
                <ErrorBanner>You are not authorised to view the requested page</ErrorBanner>
            }

            <Footer />
        </div>
    );
};