import React, { ReactNode } from 'react';

interface ErrorBannerProps {
    children: ReactNode;
    isSticky?: boolean;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({ children, isSticky }) => {
    return (
        <div className={`p-2 rounded-lg shadow-lg bg-red-600 block w-full text-white font-medium text-center mb-4 ${isSticky ? "sticky top-2" : ""}`}>
            {children}
        </div>
    );
}