import React, { useState, useEffect } from 'react';

type TimeLeft = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
} | null;

export const getDurationFormatted = (duration: number | undefined): string => {
    if (duration === null) {
        return "";
    }

    duration = Number(duration);
    const days = Math.floor(duration / 86400);
    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    return getTimeFormatted("", false, days, hours, minutes, seconds) as string;
};

export const getTimeFormatted = (
    className: string | undefined,
    isDoomsdayFormat: boolean,
    days: number,
    hours: number,
    minutes: number,
    seconds: number
): JSX.Element | string => {
    if (isDoomsdayFormat) {
        return (
            <div className="p-4">
                <div className="flex flex-col items-center justify-center w-full h-full gap-8 sm:gap-16">
                    <div className="flex justify-center gap-3 sm:gap-8">
                        {[
                            { label: days === 1 ? "Day" : "Days", value: days },
                            { label: hours === 1 ? "Hour" : "Hours", value: hours },
                            { label: minutes === 1 ? "Minute" : "Minutes", value: minutes },
                            { label: seconds === 1 ? "Second" : "Seconds", value: seconds },
                        ].map(({ label, value }, index) => (
                            <div className="flex flex-col gap-5 relative" key={index}>
                                <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-center items-center bg-gray-600 rounded-lg">
                                    <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-white">
                                        {value}
                                    </span>
                                </div>
                                <span className="text-xs sm:text-2xl text-center">{label}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        const time =
            (days > 0 ? days + ":" : "") +
            (hours > 0 || days > 0 ? hours + ":" : "") +
            (minutes > 0 ? (hours > 0 && minutes < 10 ? "0" : "") + minutes + ":" : "0:") +
            (seconds < 10 ? "0" : "") +
            seconds;

        return <span className={className ?? "text-3xl block"}>{time}</span>;
    }
};

interface CountdownProps {
    targetDateUtc: string;
    isPaused: boolean;
    className?: string;
    isDoomsdayFormat: boolean;
}

export const Countdown: React.FC<CountdownProps> = ({
    targetDateUtc,
    isPaused,
    className,
    isDoomsdayFormat,
}) => {
    const [timeLeft, setTimeLeft] = useState<TimeLeft>(null);

    const calculateTimeLeft = (targetUtc: string): TimeLeft => {
        const now = new Date();
        const [year, month, day, hour, minute, second] = targetUtc.split(/[-T:]/).map(Number);
        const targetDate = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
        const difference = targetDate.getTime() - now.getTime();

        if (difference < 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {
        if (isPaused) return;

        const timer = setInterval(() => {
            if (targetDateUtc) {
                setTimeLeft(calculateTimeLeft(targetDateUtc));
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDateUtc, isPaused]);

    return (
        <>
            {isPaused ? (
                <span className={className ?? "text-3xl block"}>Paused</span>
            ) : (
                timeLeft &&
                getTimeFormatted(
                    className,
                    isDoomsdayFormat,
                    timeLeft.days,
                    timeLeft.hours,
                    timeLeft.minutes,
                    timeLeft.seconds
                )
            )}
        </>
    );
};