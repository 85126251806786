//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IUserMenu
{
	legacyBaseUrl: string;
	menu: IUserMenuItem[];
}
export interface IUserMenuItem
{
	isExternal: boolean;
	name: string;
	subMenu: IUserMenuItem[];
	url: string;
}
export interface IAgmCreateGroupModel extends IBallotModel
{
	description?: string;
	duration?: number;
	options: string[];
	question: string;
}
export interface IAgmUpdateGroupModel extends IAgmCreateGroupModel
{
	groupId: number;
}
export interface IGetAgmStatusModel
{
	ballot: IBallotView;
	groups: IAgmGroupView[];
	status: IAgmGroupStatusView;
}
export interface IBallotView
{
	allowStatisticsPage: boolean;
	archiveUsersInterval?: number;
	archiveVotersInterval?: number;
	archiveVotesInterval?: number;
	ballotId: number;
	ballotName: string;
	ballotStatus: BallotStatus;
	closeDate: any;
	closeDateFormattedAbbrTz: string;
	closeDateUtc: any;
	defaultGroupId?: number;
	isAddressVoting: boolean;
	isAgm: boolean;
	isArchiveEnabled: boolean;
	isBallotClosed: boolean;
	isBallotNotOpenYet: boolean;
	isBallotOpen: boolean;
	isBallotOpened: boolean;
	isDeleted: boolean;
	isPhoneVoting: boolean;
	isPublished: boolean;
	isSmsVoting: boolean;
	isSubmitVotesEachGroup: boolean;
	numBallotUsers: number;
	numVoters: number;
	numVotes: number;
	openDate: any;
	openDateFormattedAbbrTz: string;
	openDateUtc: any;
	organisation: string;
	organisationId: number;
	organisationLogo: string;
	pauseTime?: any;
	timezone: string;
	timezoneInfo: any;
	votingMethods: number[];
}
export interface IBallotModel
{
	ballotId: number;
}
export interface IBallotGroupResultView
{
	groupId: number;
	includesWeighting: boolean;
	totalVoters: number;
	votersResponded: number;
}
export interface IAgmGroupResultView extends IBallotGroupResultView
{
	groupReportDownloadUrl: string;
}
export interface IAgmGroupStatusView
{
	currentGroupSecondsRemaining?: number;
	hasAdhocQuestion: boolean;
	hasMainBallot: boolean;
	isAllAgmGroupsClosed: boolean;
	isBallotLiveResultsEnabled: boolean;
	lastClosedGroupId?: number;
	openGroup: IAgmGroupView;
	status: AgmVoteStatus;
	statusStatement: string;
	totalVoters: number;
	votersVoted: number;
}
export interface IAgmGroupView
{
	ballotId: number;
	closeTime?: any;
	closeTimeFormattedAbbrTz: string;
	duration?: number;
	durationFormatted: string;
	groupDescription: string;
	groupId: number;
	groupName: string;
	isLastOpenGroupBeforeClose: boolean;
	isMainBallot: boolean;
	isWithdrawn: boolean;
	openTime?: any;
	openTimeFormattedAbbrTz: string;
	options: string[];
	pauseTime?: any;
	secondsRemaining?: number;
	status: AgmGroupStatus;
}
export interface IOptionResult
{
	isHeader: boolean;
	isWinner: boolean;
	optionId: number;
	optionName: string;
	percentageResponded: number;
	percentageTotalVoters: number;
	score: number;
	yesVotes: number;
	noVotes: number;
}
export interface IVoterView
{
	address: string;
	ballotId: number;
	email: string;
	emailBounceDate?: any;
	emailComplaintDate?: any;
	emailErrorDate?: any;
	emailStatus: string;
	errorEmail: string;
	guid: string;
	isAttending: boolean;
	isUnsubscribed: boolean;
	name: string;
	phone: string;
	proxyEmail: string;
	receipt: string;
	secondaryEmail: string;
	uniqueId: string;
	voterGroup: string;
	voterPersonalInfo: string;
	voteSubmitDate?: any;
	voteSubmitDateFormatted: string;
	voteWeight: number;
}
export interface IVoterAttendanceView
{
	ballotId: number;
	email: string;
	guid: string;
	hasCastVote: boolean;
	isAttending: boolean;
	name: string;
	uniqueId: string;
}
export interface ITableQueryModel
{
	filters: IGridFilter[];
	pageIndex: number;
	pageSize: number;
	sortColumns: IGridSortColumn[];
}
export interface IBallotTableModel extends ITableQueryModel
{
	ballotId: number;
}
export interface IGridFilter
{
	member: string;
	operator: string;
	value: string;
}
export interface IGridSortColumn
{
	direction: string;
	member: string;
}
export interface IVoterAttendanceRequest extends IBallotModel
{
	isShowAttending: boolean;
	search: string;
}
export interface IUpdateVoterAttendingRequest extends IBallotModel
{
	isAttending: boolean;
	voterGuid: string;
}
export enum BallotStatus {
	Draft = 0,
	Published = 1,
	Live = 2,
	Complete = 3,
	Paused = 4
}
export enum AgmVoteStatus {
	BallotNotOpen = 0,
	BallotInProgress = 1,
	BallotComplete = 2,
	AgmInProgress = 3,
	AgmGroupOpen = 4,
	AgmGroupPaused = 5
}
export enum AgmGroupStatus {
	Open = 0,
	Closed = 1,
	Paused = 2,
	Withdrawn = 3,
	NotOpen = 4,
	NotAgm = 5
}
export class PortalApiRoutes
{
	public static ACCOUNT: string = `/api/account`;
	public static ACCOUNT_ACTION_LOGIN: string = `login`;
	public static ACCOUNT_ACTION_LOGOUT: string = `logout`;
	public static ACCOUNT_ACTION_TWO_FACTOR: string = `twofactor`;
	public static ACCOUNT_LOGIN: string = `/api/account/login`;
	public static ACCOUNT_LOGOUT: string = `/api/account/logout`;
	public static ACCOUNT_ROUTE: string = `/api/account/[action]`;
	public static ACCOUNT_TWO_FACTOR: string = `/api/account/twofactor`;
	public static ACCOUNT_URL_BASE: string = `/api/account/`;
	public static AGM: string = `/api/ballot/agm`;
	public static AGM_ACTION_CLOSE: string = `closeagm`;
	public static AGM_ACTION_CLOSE_GROUP: string = `close`;
	public static AGM_ACTION_CREATE_GROUP: string = `create`;
	public static AGM_ACTION_OPEN_GROUP: string = `open`;
	public static AGM_ACTION_PAUSE_GROUP: string = `pause`;
	public static AGM_ACTION_RESULTS: string = `results`;
	public static AGM_ACTION_UNPAUSE_GROUP: string = `unpause`;
	public static AGM_ACTION_UPDATE_GROUP: string = `update`;
	public static AGM_CLOSE: string = `/api/ballot/agm/closeagm`;
	public static AGM_CLOSE_GROUP: string = `/api/ballot/agm/close`;
	public static AGM_CREATE_GROUP: string = `/api/ballot/agm/create`;
	public static AGM_OPEN_GROUP: string = `/api/ballot/agm/open`;
	public static AGM_PAUSE_GROUP: string = `/api/ballot/agm/pause`;
	public static AGM_RESULTS: string = `/api/ballot/agm/results`;
	public static AGM_ROUTE: string = `/api/ballot/agm/[action]`;
	public static AGM_UNPAUSE_GROUP: string = `/api/ballot/agm/unpause`;
	public static AGM_UPDATE_GROUP: string = `/api/ballot/agm/update`;
	public static AGM_URL_BASE: string = `/api/ballot/agm/`;
	public static API_BASE: string = `/api`;
	public static ARCHIVE: string = `/api/ballot/archive`;
	public static ARCHIVE_ACTION_UPDATE: string = `update`;
	public static ARCHIVE_ROUTE: string = `/api/ballot/archive/[action]`;
	public static ARCHIVE_UPDATE: string = `/api/ballot/archive/update`;
	public static ARCHIVE_URL_BASE: string = `/api/ballot/archive/`;
	public static BALLOT: string = `/api/ballot`;
	public static BALLOT_ACTION_CREATE: string = `create`;
	public static BALLOT_ACTION_DELETE: string = `delete`;
	public static BALLOT_ACTION_PUBLISH: string = `publish`;
	public static BALLOT_ACTION_UPDATE: string = `update`;
	public static BALLOT_CREATE: string = `/api/ballot/create`;
	public static BALLOT_DELETE: string = `/api/ballot/delete`;
	public static BALLOT_PUBLISH: string = `/api/ballot/publish`;
	public static BALLOT_ROUTE: string = `/api/ballot/[action]`;
	public static BALLOT_UPDATE: string = `/api/ballot/update`;
	public static BALLOT_URL_BASE: string = `/api/ballot/`;
	public static BALLOTS: string = `/api/ballots`;
	public static BALLOTS_ACTION_TIMELINE: string = `timeline`;
	public static BALLOTS_ROUTE: string = `/api/ballots/[action]`;
	public static BALLOTS_TIMELINE: string = `/api/ballots/timeline`;
	public static BALLOTS_URL_BASE: string = `/api/ballots/`;
	public static NOTIFICATIONS: string = `/api/ballot/notifications`;
	public static NOTIFICATIONS_ROUTE: string = `/api/ballot/notifications/[action]`;
	public static ROUTE_ACTION: string = `/[action]`;
	public static USER: string = `/api/user`;
	public static USER_ACTION_INFO: string = `info`;
	public static USER_INFO: string = `/api/user/info`;
	public static USER_ROUTE: string = `/api/user/[action]`;
	public static USER_URL_BASE: string = `/api/user/`;
	public static VOTER_ATTENDANCE: string = `/api/ballot/voters/attendance`;
	public static VOTER_ATTENDANCE_ACTION_BALLOT: string = `ballot`;
	public static VOTER_ATTENDANCE_ACTION_UPDATE: string = `update`;
	public static VOTER_ATTENDANCE_BALLOT: string = `/api/ballot/voters/attendance/ballot`;
	public static VOTER_ATTENDANCE_ROUTE: string = `/api/ballot/voters/attendance/[action]`;
	public static VOTER_ATTENDANCE_UPDATE: string = `/api/ballot/voters/attendance/update`;
	public static VOTER_ATTENDANCE_URL_BASE: string = `/api/ballot/voters/attendance/`;
	public static VOTERS: string = `/api/ballot/voters`;
	public static VOTERS_ACTION_CREATE: string = `create`;
	public static VOTERS_CREATE: string = `/api/ballot/voters/create`;
	public static VOTERS_ROUTE: string = `/api/ballot/voters/[action]`;
	public static VOTERS_URL_BASE: string = `/api/ballot/voters/`;
	public static VOTING_OPTIONS: string = `/api/ballot/votingoptions`;
	public static VOTING_OPTIONS_ACTION_CREATE: string = `create`;
	public static VOTING_OPTIONS_CREATE: string = `/api/ballot/votingoptions/create`;
	public static VOTING_OPTIONS_ROUTE: string = `/api/ballot/votingoptions/[action]`;
	public static VOTING_OPTIONS_URL_BASE: string = `/api/ballot/votingoptions/`;
}
export class PortalRoutes
{
	public static ACCOUNT_LOGIN: string = `/login`;
	public static AGM_ADMIN: string = `/agm/admin`;
	public static AGM_RESULTS: string = `/agm/results`;
	public static BALLOT: string = `/ballot`;
	public static BALLOTS: string = `/ballots`;
	public static BALLOTS_TIMELINE: string = `/ballots/timeline`;
	public static HOME: string = `/`;
	public static NOT_AUTHORISED: string = `/notauthorised`;
	public static VOTER_ATTENDANCE: string = `/ballot/voters/attendance`;
	public static VOTERS: string = `/ballot/voters`;
}
