import React, { useCallback, useEffect, useState } from 'react';
import { LoadingPanel } from '../../common/LoadingPanel';
import { useQueryParam } from '../../../index'
import { IBallotView, IUpdateVoterAttendingRequest, IVoterAttendanceRequest, IVoterAttendanceView, PortalApiRoutes } from '../../../types/portalTypes';
import { Header } from '../../portal/Header';
import { ErrorBanner } from '../../common/ErrorBanner';
import axios from 'axios';
import { LoadingButton } from '../../common/LoadingButton';
import { Toggle } from '../../common/Toggle';

interface VoterAttendanceProps { }

export const PANEL_CSS = "bg-white py-8 px-10 shadow sm:rounded-lg mb-4 "

export const VoterAttendance: React.FC<VoterAttendanceProps> = () => {
    const ballotId: string | null = useQueryParam('ballotId')
    const [isLoading, setLoading] = useState<boolean>(true)
    const [ballot, setBallot] = useState<IBallotView>()
    const [error, setError] = useState<string>()
    const [voters, setVoters] = useState<IVoterAttendanceView[]>()
    const [search, setSearch] = useState<string>("");

    const getVoters = useCallback(() => {
        if (!ballotId) {
            return
        }

        //setVotersLoading(true)

        const requestData: IVoterAttendanceRequest = {
            ballotId: parseInt(ballotId),
            search,
            isShowAttending: true
        };

        axios.post(PortalApiRoutes.VOTER_ATTENDANCE, requestData)
            .then((response) => {
                if (response.data.isSuccessful) {
                    setVoters(response.data.payload)
                }
                else {
                    setError(response.data.errorMessage)
                }
                //setVotersLoading(false)
            })
            .catch((error) => {
                alert(error)
            })
    }, [ballotId, search])

    const getBallot = useCallback(() => {
        axios.post(PortalApiRoutes.VOTER_ATTENDANCE_BALLOT, {
            ballotId: ballotId
        })
            .then((response) => {
                if (response.data.isSuccessful) {
                    setBallot(response.data.payload)
                }
                else {
                    setError(response.data.errorMessage)
                }
                setLoading(false)
            })
            .catch((error) => {
                alert(error)
            })
    }, [ballotId])

    useEffect(() => {
        if (!ballotId) {
            return
        }

        getBallot()
    }, [ballotId, getBallot])

    useEffect(() => {
        getVoters()
    }, [getVoters, search])

    const handleUpdateAttending = useCallback((guid: string, isAttending: boolean) => {
        if (!ballotId) {
            return
        }

        const requestData: IUpdateVoterAttendingRequest = {
            ballotId: parseInt(ballotId),
            voterGuid: guid,
            isAttending
        }

        axios.post(PortalApiRoutes.VOTER_ATTENDANCE_UPDATE, requestData)
            .then((response) => {
                if (response.data.isSuccessful) {
                    const updatedVoter = response.data.payload // Assuming payload contains the updated voter

                    setVoters((prevVoters) => {
                        if (!prevVoters) return []

                        return prevVoters.map(voter =>
                            voter.guid === guid ? updatedVoter : voter
                        )
                    })
                } else {
                    setError(response.data.errorMessage)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }, [ballotId])

    return (
        <div className="container">

            <LoadingPanel className={`${PANEL_CSS} sm:mt-8 text-center`}
                isLoading={isLoading}
                loadingText="Loading Ballot..."
            />

            {!isLoading && ballot &&
                <>
                    <div className={`${PANEL_CSS} sm:mt-8`} >
                        <Header ballot={ballot} />
                    </div>
                    {error &&
                        <div className="sm:mt-8">
                            <ErrorBanner>{error}</ErrorBanner>
                        </div>
                    }

                    <div className={`${PANEL_CSS} sm:mt-8`}>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                            <span className="text-2xl mb-4 sm:mb-0">Voter Attendance</span>
                            <div className="flex items-center whitespace-nowrap">
                                <span className="mr-2">Search</span>
                                <input
                                    type="text"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    className="p-2 border rounded"
                                />
                            </div>
                        </div>
                        {voters &&
                            <>
                                {voters.length === 0 && search &&
                                    <span>No voters found for "{search}"...</span>
                                }
                                {
                                    voters.map((voter, index) =>
                                        <div key={voter.guid}
                                            className="flex items-center p-4 hover:bg-gray-100 border-b -mx-2">
                                            <span className="mr-2 min-w-20 md:pr-4 pr-2">{voter.uniqueId}</span>

                                            {/* Stacked view for small devices */}
                                            <div className="flex flex-col md:hidden w-full">
                                                <span className="w-full break-words">{voter.name}</span>
                                                <span className="w-full break-words">{voter.email}</span>
                                            </div>

                                            {/* Inline view for larger devices */}
                                            <div className="hidden md:flex w-full items-center">
                                                <span className="w-48 mr-2 break-words">{voter.name}</span>
                                                <span className="flex-auto mr-2 break-words">{voter.email}</span>
                                            </div>

                                            <div className="min-w-24">
                                                <Toggle isOn={voter.isAttending}
                                                    cssOnColor="bg-green-600"
                                                    onTooltip="Attending"
                                                    offTooltip="Not Attending"
                                                    handleToggle={() => handleUpdateAttending(voter.guid, !voter.isAttending)}
                                                    errorText={voter.hasCastVote ? `${voter.name} has already cast a vote. Contact Administrator if this voter needs to be removed from attendance.` : null} />
                                            </div>
                                        </div>

                                    )
                                }
                            </>
                        }
                    </div>
                </>
            }

        </div>
    );
}