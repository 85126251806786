import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface RoutePageProps extends RouteProps {
    title: string;
}

export const RoutePage: React.FC<RoutePageProps> = ({ title, children }) => {
    return (
        <Route
            render={() => (
                <div>
                    <h1 className="mx-4 mb-2">{title}</h1>
                    {children}
                </div>
            )}
        />
    );
};