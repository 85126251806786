import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AgmAdmin, AgmAdminProps } from './AgmAdmin';
import { AgmResults, AgmResultsProps } from './AgmResults';
import { useQueryParam } from '../../index'
import { ErrorBanner } from '../common/ErrorBanner';
import { Header } from '../portal/Header';
import { LoadingPanel } from '../common/LoadingPanel'
import { AgmStatus } from './AgmStatus';
import { InfoBanner } from '../common/InfoBanner';
import { Footer } from '../common/page/Footer';
import { AgmVoteStatus, IAgmGroupStatusView, IAgmGroupView, IBallotView, IGetAgmStatusModel, PortalApiRoutes } from '../../types/portalTypes';
import { LoadingButton } from '../common/LoadingButton';

type AgmComponentProps = AgmAdminProps | AgmResultsProps;

const agmComponents: { [key: string]: React.ComponentType<AgmComponentProps> } = {
    admin: AgmAdmin as React.ComponentType<AgmComponentProps>,
    results: AgmResults as React.ComponentType<AgmComponentProps>,
}

interface AgmAppProps {
    type: 'admin' | 'results'
}

export const PANEL_CSS = "bg-white py-8 px-10 shadow sm:rounded-lg mb-4 "

export const AgmApp: React.FC<AgmAppProps> = ({ type }) => {
    const AgmComponent = agmComponents[type]
    const ballotId: string | null = useQueryParam('agmId')
    const [isLoading, setLoading] = useState<boolean>(true)
    const [isCloseAgmLoading, setCloseAgmLoading] = useState<boolean>(false)
    const [ballot, setBallot] = useState<IBallotView>()
    const [status, setStatus] = useState<IAgmGroupStatusView>()
    const [groups, setGroups] = useState<IAgmGroupView[]>()
    const [error, setError] = useState<string>()

    const getAgmStatus = (): void => {
        axios.post(PortalApiRoutes.AGM, {
            ballotId: ballotId
        })
            .then((response) => {
                if (response.data.isSuccessful) {
                    handleAgmResponse(response.data.payload)
                }
                else {
                    setError(response.data.errorMessage)
                }
                setLoading(false)
            })
            .catch((error) => {
                alert(error)
            })
    }

    const onStatusResponse = (response: IGetAgmStatusModel): void => {
        handleAgmResponse(response)
    }

    const onAgmStatusUpdate = (oldStatus: IAgmGroupStatusView, newStatus: IAgmGroupStatusView): void => {
        getAgmStatus()
    }

    const handleAgmResponse = (responsePayload: IGetAgmStatusModel): void => {
        let ballot = responsePayload.ballot
        setBallot(ballot)
        setStatus(responsePayload.status)
        setGroups(responsePayload.groups)

        // Set page title to ballot
        document.title = ballot.ballotName;

        if (!ballot.isAgm) {
            setError("Ballot is not enabled for AGM voting")
        }
    }

    const handleCloseAgm = (): void => {
        setCloseAgmLoading(true)
        axios.post(PortalApiRoutes.AGM_CLOSE, {
            ballotId: ballotId
        })
            .then((response) => {
                if (response.data.isSuccessful) {
                    handleAgmResponse(response.data.payload)
                }
                else {
                    setError(response.data.errorMessage)
                }
                setCloseAgmLoading(false)
            })
            .catch((error) => {
                alert(error)
            })
    }

    useEffect(() => {
        if (!ballotId) {
            return
        }

        getAgmStatus()
    }, [ballotId])

    return (
        <div className="container">

            <LoadingPanel className={`${PANEL_CSS} sm:mt-8 text-center`}
                isLoading={isLoading}
                loadingText="Loading AGM..."
            />

            {!isLoading && ballot && groups && status &&
                <>
                    <div className={`${PANEL_CSS} sm:mt-8`} >
                        <Header ballot={ballot}>
                            {type === 'admin' && status.status !== AgmVoteStatus.BallotComplete && status.status !== AgmVoteStatus.BallotNotOpen &&
                                <>
                                    <LoadingButton className="btn-red"
                                        onBtnClick={handleCloseAgm}
                                        btnText="Close AGM"
                                        isLoading={isCloseAgmLoading}
                                        confirmText="Are you sure you want to close the AGM?"
                                        title="Click to close AGM" />
                                </>
                            }
                        </Header>
                    </div>
                    {error &&
                        <div className="sm:mt-8">
                            <ErrorBanner>{error}</ErrorBanner>
                        </div>
                    }

                    <AgmStatus ballotId={ballot.ballotId}
                        onAgmStatusUpdate={onAgmStatusUpdate} />

                    {status?.statusStatement &&
                        <InfoBanner>
                            {status.statusStatement}
                        </InfoBanner>
                    }

                    <AgmComponent ballot={ballot}
                        agmGroups={groups}
                        status={status}
                        onStatusUpdate={onStatusResponse} />
                </>
            }

            <Footer />
        </div >
    )
};