import React, { useState, useEffect } from 'react'

export const TablePaging = ({
    pageIndex,
    pageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    totalRows }) => {

    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(pageCount)]

    return (
        <>
            {pageCount > 1 &&
                <div className="flex">
                    <button className="btn mr-2"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}>First</button>

                    <button className="btn mr-2"
                        onClick={previousPage}
                        disabled={!canPreviousPage}>Prev</button>

                    <button className="btn mr-2"
                        onClick={nextPage}
                        disabled={!canNextPage}>Next</button>

                    <button className="btn mr-2"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}>Last</button>
                    <span className="mr-2 flex-1 text-right align-middle leading-[40px]">
                        Page {pageIndex + 1} of {pageCount} ({totalRows} items)
                    </span>
                </div>
            }
        </>
    )
}