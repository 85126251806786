import React, { useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';

interface AgmStatusProps {
    ballotId: number
    onAgmStatusUpdate: any
}

export const AgmStatus: React.FC<AgmStatusProps> = ({ ballotId, onAgmStatusUpdate }) => {
    const connectionRef = useRef<HubConnection>();

    useEffect(() => {
        if (!connectionRef.current || connectionRef.current.state === signalR.HubConnectionState.Disconnected) {
            const connectionUrl = `hubs/agm?b=${ballotId}`;
            const newConnection = new signalR.HubConnectionBuilder()
                .withUrl(connectionUrl)
                .withAutomaticReconnect()
                .build();

            connectionRef.current = newConnection
        }
    }, [ballotId]);

    useEffect(() => {
        const connection = connectionRef.current;

        if (connection && connection.state === signalR.HubConnectionState.Disconnected) {
            connection.start()
                .then(() => {
                    connection.on('agmStatusUpdate', payload => {
                        onAgmStatusUpdate(payload.oldStatus, payload.newStatus)
                    })

                    connection.on('connectionRejected', message => {
                        // Cancel auto reconnect
                        connection.stop()
                    })

                    // Subscribe for AGM updates
                    connection.send('subscribe')
                })
                .catch(err => console.error(err));
        }
    }, [connectionRef, onAgmStatusUpdate]);

    return (
        <></>
    );
}