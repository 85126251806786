import React from 'react'

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
            <input
                className="border-black-300"
                value={filterValue || ''}
                onChange={e => setFilter(e.target.value)} />
    )
}