import React, { useState } from 'react';
import axios from 'axios';
import dompurify from 'dompurify';
import { LoadingButton } from '../common/LoadingButton';
import { Countdown, getDurationFormatted } from '../common/Countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AgmVoteStatus, IAgmGroupStatusView, IAgmGroupView, PortalApiRoutes } from '../../types/portalTypes';

interface AgmAdminGroupProps {
    ballotId: number
    group: IAgmGroupView
    status: IAgmGroupStatusView
    onStatusUpdate: any
    setError: any
    onShowEdit?: any
}

export const AgmAdminGroup: React.FC<AgmAdminGroupProps> = ({ ballotId, group, status, onStatusUpdate, setError, onShowEdit }) => {
    const [isOpenLoading, setOpenLoading] = useState<boolean>(false)
    const [isPauseLoading, setPauseLoading] = useState<boolean>(false)
    const [isUnpauseLoading, setUnpauseLoading] = useState<boolean>(false)
    const [isCloseLoading, setCloseLoading] = useState<boolean>(false)

    const handleOpenGroup = (groupId: number): void => {
        handleCommand(groupId, PortalApiRoutes.AGM_OPEN_GROUP, setOpenLoading)
    }

    const handlePauseGroup = (groupId: number): void => {
        handleCommand(groupId, PortalApiRoutes.AGM_PAUSE_GROUP, setPauseLoading)
    }

    const handleUnpauseGroup = (groupId: number): void => {
        handleCommand(groupId, PortalApiRoutes.AGM_UNPAUSE_GROUP, setUnpauseLoading)
    }

    const handleCloseGroup = (groupId: number): void => {
        handleCommand(groupId, PortalApiRoutes.AGM_CLOSE_GROUP, setCloseLoading)
    }

    const handleCommand = (groupId: number, url: string, setLoading: any): void => {
        setLoading(true)
        axios.post(url, {
            ballotId: ballotId,
            groupId: groupId
        })
            .then((response) => {
                if (response.data.isSuccessful) {
                    onStatusUpdate(response.data.payload)
                }
                else {
                    setError(response.data.errorMessage)
                }
                setLoading(false)
            })
            .catch(function (error) {
                alert(error)
            })
    }

    const isOpenGroup = (groupId: number): boolean => {
        return status.openGroup?.groupId === groupId
    }

    const isGroupOpen = () => {
        return status.status === AgmVoteStatus.AgmGroupOpen
            || status.status === AgmVoteStatus.AgmGroupPaused
    }

    const isAgmOpen = () => {
        return status.status === AgmVoteStatus.AgmInProgress
            || status.status === AgmVoteStatus.AgmGroupOpen
            || status.status === AgmVoteStatus.AgmGroupPaused
    }

    return (
        <>
            <div className="flex flex-auto items-center">
                <span className="mr-2 cursor-pointer" onClick={() => onShowEdit(true, group)} title="Edit question"><FontAwesomeIcon icon={faCog} /></span>
                <span className={`flex-auto ${isOpenGroup(group.groupId) ? "text-2xl" : "text-xl"}`}
                    dangerouslySetInnerHTML={{ __html: dompurify.sanitize(group.groupName.replace("\n", "</br>")) }} />
                {isOpenGroup(group.groupId) && group.duration ?
                    <>
                        {status.status === AgmVoteStatus.AgmGroupPaused ?
                            <span className="text-xl">{getDurationFormatted(group.secondsRemaining)}</span>
                            :
                            <Countdown
                                className="text-xl"
                                targetDateUtc={status.openGroup.closeTime}
                                isPaused={false}
                                isDoomsdayFormat={false} />
                        }
                    </>
                    :
                    <>
                        {group.duration &&
                            <span className="text-xl" title={`Question will be open for ${group.duration} seconds`}>{getDurationFormatted(group.duration)}</span>
                        }
                    </>
                }
            </div>
            {isAgmOpen() &&
                <div className="space-x-2 ml-4">
                    {status.status === AgmVoteStatus.AgmInProgress &&
                        <LoadingButton className="btn-green"
                            onBtnClick={() => handleOpenGroup(group.groupId)}
                            btnText="Open Voting"
                            isLoading={isOpenLoading}
                            confirmText="Are you sure you want to open voting for this AGM question?"
                            title="Click to open question for voting" />
                    }
                    {isOpenGroup(group.groupId) && status.status === AgmVoteStatus.AgmGroupOpen && group.duration !== null &&
                        <LoadingButton className="btn"
                            onBtnClick={() => handlePauseGroup(group.groupId)}
                            btnText="Pause"
                            isLoading={isPauseLoading}
                            confirmText="Are you sure you want to pause the countdown? Voters will still be able to vote while the countdown is paused."
                            title="Click to pause countdown for question" />
                    }
                    {isOpenGroup(group.groupId) && status.status === AgmVoteStatus.AgmGroupPaused && group.duration !== null &&
                        <LoadingButton className="btn"
                            onBtnClick={() => handleUnpauseGroup(group.groupId)}
                            btnText="Unpause"
                            isLoading={isUnpauseLoading}
                            confirmText="Are you sure you want to unpause the countdown?"
                            title="Click to unpause countdown for question" />
                    }
                    {isOpenGroup(group.groupId) && isGroupOpen() &&
                        <LoadingButton className="btn-red"
                            onBtnClick={() => handleCloseGroup(group.groupId)}
                            btnText="Close Voting"
                            isLoading={isCloseLoading}
                            confirmText="Are you sure you want to close voting for this AGM question?"
                            title="Click to close voting for question" />
                    }
                </div>
            }
        </>
    );
};