import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface LoadingButtonProps {
    isLoading: boolean
    className: string
    btnText: string
    onBtnClick: any
    isSubmit?: boolean
    isDisabled?: boolean
    confirmText?: string
    title?: string
    errorText?: string | null
}

export const LoadingButton = ({ isLoading, className, btnText, onBtnClick, isSubmit, isDisabled, confirmText, title, errorText }: LoadingButtonProps) => {
    const getBtnText = () => {
        return btnText ?? "Submit"
    }

    const [showConfirm, setShowConfirm] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        if (errorText) {
            setShowError(true);
        }
        else if (confirmText) {
            setShowConfirm(true);
        }
        else {
            handleSubmitClick();
        }
    }

    const handleSubmitClick = () => {
        setShowConfirm(false);
        onBtnClick();
    }

    return (
        <>
            {isLoading && <button disabled={isLoading}
                type={isSubmit ? "submit" : "button"}
                className={className}
                title={title ? title : undefined}>
                <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-2" />
                {getBtnText()}
            </button>}
            {isDisabled && <button disabled={isDisabled}
                type={isSubmit ? "submit" : "button"}
                className={className}>
                {getBtnText()}
            </button>}
            {!isLoading && !isDisabled && <button
                type={isSubmit ? "submit" : "button"}
                className={className}
                onClick={handleButtonClick}
                title={title ? title : undefined}>
                {getBtnText()}
            </button>}
            {showConfirm &&
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="bg-white p-4 sm:p-6">
                                <h2 id="modal-headline" className="text-lg leading-6 font-medium">
                                    Confirm
                                </h2>
                                <div className="mt-2">
                                    <p>
                                        {confirmText}
                                    </p>
                                </div>
                                <div className="mt-2 sm:flex sm:flex-row-reverse">
                                    <button
                                        onClick={handleSubmitClick}
                                        type="button"
                                        className="btn ml-0 sm:ml-2">
                                        Yes
                                    </button>
                                    <button
                                        onClick={() => setShowConfirm(false)}
                                        type="button"
                                        className="btn-gray ml-2 sm:ml-0">
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {showError && errorText &&
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <div
                            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="bg-white p-4 sm:p-6">
                                <h2 id="modal-headline" className="text-lg leading-6 font-medium">
                                    Error
                                </h2>
                                <div className="mt-2">
                                    <p>
                                        {errorText}
                                    </p>
                                </div>
                                <div className="mt-2 sm:flex sm:flex-row-reverse">
                                    <button
                                        onClick={() => setShowError(false)}
                                        type="button"
                                        className="btn ml-0 sm:ml-2">
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}