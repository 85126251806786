import React, { useEffect, useState } from 'react'
import axios from 'axios';

import '../../tailwind.css'
import '../../custom.css'
import { BallotStatus, PortalApiRoutes } from '../../types/portalTypes';

// Define an interface for the ballot object
interface Ballot {
    id: string
    name: string
    startTime: Date
    startTimeFormatted: string
    endTime: Date
    endTimeFormatted: string
    status: BallotStatus
}

interface BallotsTimelineProps {
    legacyBaseUrl: string | undefined
}

export const BallotsTimeline: React.FC<BallotsTimelineProps> = ({ legacyBaseUrl }) => {
    const [ballots, setBallots] = useState<Ballot[]>([])

    const fetchBallots = () => {
        axios.post(PortalApiRoutes.BALLOTS_TIMELINE,
            {
                pageSize: 25,
                pageIndex: 0
            })
            .then((response) => {
                const fetchedBallots: Ballot[] = response.data.payload.map((b: any) => ({
                    id: b.ballotId,
                    name: b.ballotName,
                    startTime: new Date(b.openDateUtc),
                    startTimeFormatted: b.openDateFormattedAbbrTz,
                    endTime: new Date(b.closeDateUtc),
                    endTimeFormatted: b.closeDateFormattedAbbrTz,
                    status: b.ballotStatus,
                }))
                setBallots(fetchedBallots)
            })
            .catch((error: any) => alert(error))
    }

    useEffect(() => {
        fetchBallots()
    }, [])

    const generateDatesBetween = (start: Date, end: Date) => {
        const dates: Date[] = []
        let current = new Date(start)
        while (current <= end) {
            dates.push(new Date(current))
            current.setDate(current.getDate() + 7) // increase the day by 1
        }
        return dates
    };

    // Sorting the ballots by close time in ascending order
    //const sortedBallots = ballots?.sort((a, b) => a.startTime.getTime() - b.endTime.getTime())

    const ganttStart = new Date()
    ganttStart.setDate(ganttStart.getDate() - 7) // 1 week ago
    const ganttEnd = new Date()
    ganttEnd.setDate(ganttEnd.getDate() + (10 * 7)) // 10 weeks ahead
    const ganttDurationInMilliseconds = ganttEnd.getTime() - ganttStart.getTime()

    // Generate timeline
    const timelineDates = generateDatesBetween(ganttStart, ganttEnd);
    const dateWidthPercentage = 100 / timelineDates.length;

    //console.log(ganttStart, ganttEnd)

    return (
        <div className="bg-white py-8 px-10 shadow mx-4 sm:rounded-lg sm:mt-8">
            {ballots &&
                <div className="flex flex-col w-full relative">
                    <div className="flex border-b border-gray-600">
                        <div className="flex-none box-border w-[300px] p-2 "></div>
                        <div className="flex-grow flex">
                            {timelineDates.map((date, index) => (
                                <div
                                    key={index}
                                    className="relative pt-9 flex flex-col items-start"
                                    style={{ width: `${dateWidthPercentage}%` }}
                                >
                                    {/* Date label */}
                                    <div
                                        className="absolute left-0 top-0 translate-x-[-50%] py-4 text-sm"
                                        style={{ transform: "translateX(-50%)" }}
                                    >
                                        {date.getDate()}/{date.getMonth() + 1}
                                    </div>
                                    {/* Tick mark */}
                                    <div className="w-[1px] h-2 bg-gray-600 mt-1"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {ballots.map(ballot => {
                        if (ballot.endTime < ganttStart || ballot.startTime > ganttEnd) {
                            //console.log("skipping", ballot.endTime, ganttStart)
                            return <React.Fragment key={ballot.id} />
                        }

                        // URL for ballot
                        const editUrl = `${legacyBaseUrl}/AdminPortal/Ballots/Dashboard.aspx?id=${ballot.id}`; // Build the dynamic URL

                        // Clamp start and end times within ganttStart and ganttEnd
                        const clampedStartTime = Math.max(ballot.startTime.getTime(), ganttStart.getTime());

                        // Calculate the margin-left (position) of the Gantt entry in percentage
                        const marginLeftPercentage = Math.max(0, ((clampedStartTime - ganttStart.getTime()) / ganttDurationInMilliseconds) * 100);

                        // Clamp end time
                        let adjustedEndTime = Math.min(ballot.endTime.getTime(), ganttEnd.getTime());

                        // Calculate the width (duration) of the Gantt entry in percentage
                        let widthPercentage = Math.min(100, ((adjustedEndTime - clampedStartTime) / ganttDurationInMilliseconds) * 100);

                        // Ensure something is rendered by at making it 1%
                        widthPercentage = Math.max(1, widthPercentage);

                        const hoverText = `${ballot.name}\nOpen: ${ballot.startTimeFormatted}\nClose: ${ballot.endTimeFormatted}\nStatus: ${ballot.status}`

                        let taskCss = "flex h-8 rounded-md "

                        switch (ballot.status) {
                            case BallotStatus.Draft:
                                taskCss += "bg-yellow-700"
                                break;
                            case BallotStatus.Published:
                                taskCss += "bg-blue-700"
                                break;
                            case BallotStatus.Live:
                                taskCss += "bg-green-700"
                                break;
                            case BallotStatus.Paused:
                                taskCss += "bg-red-700"
                                break;
                            default:
                                taskCss += "bg-gray-700"
                                break;
                        }

                        if (ballot.startTime < ganttStart) {
                            taskCss += " rounded-l-none"
                        }

                        if (ballot.endTime > ganttEnd) {
                            taskCss += " rounded-r-none"
                        }

                        return (
                            <div key={ballot.id} className="flex items-center border-b border-gray-300">
                                <a className="w-[300px] py-3 px-2 bg-gray-100 text-sm truncate text-black" title={ballot.name} href={editUrl} target="_blank" rel="noreferrer">{ballot.name}</a>
                                <div className="flex-grow relative">
                                    <a className={taskCss}
                                        style={{ marginLeft: `${marginLeftPercentage}%`, width: `${widthPercentage}%` }}
                                        title={hoverText}
                                        href={editUrl} target="_blank" rel="noreferrer" />
                                </div>
                            </div>
                        )
                    })}
                </div>
            }

            <div className="flex mt-4 justify-center">
                <div className="w-[100px] text-sm p-1 rounded-md bg-yellow-700 text-white text-center mr-2">Draft</div>
                <div className="w-[100px] text-sm p-1 rounded-md bg-blue-700 text-white text-center mr-2">Published</div>
                <div className="w-[100px] text-sm p-1 rounded-md bg-green-700 text-white text-center mr-2">Live</div>
                <div className="w-[100px] text-sm p-1 rounded-md bg-red-700 text-white text-center mr-2">Paused</div>
                <div className="w-[100px] text-sm p-1 rounded-md bg-gray-700 text-white text-center">Closed</div>
            </div>
        </div>
    );
}