import React from 'react';
import { BallotStatus, IBallotView } from '../../types/portalTypes';

interface HeaderProps {
    ballot: IBallotView
    isShowStatus?: boolean
}

export const Header: React.FC<HeaderProps> = ({ children, ballot, isShowStatus }) => {
    let statusBadge;

    switch (ballot.ballotStatus) {
        case BallotStatus.Draft:
            statusBadge = <span className="rounded-lg text-white py-2 px-4 font-bold uppercase bg-yellow-500"
                title="AGM ballot is in draft state">Draft</span>
            break;
        case BallotStatus.Published:
            statusBadge = <span className="rounded-lg text-white py-2 px-4 font-bold uppercase bg-blue-500"
                title="AGM ballot is pending open">Pending Open</span>
            break;
        case BallotStatus.Live:
            statusBadge = <span className="rounded-lg text-white py-2 px-4 font-bold uppercase bg-green-500"
                title="AGM ballot is live">Live</span>
            break;
        case BallotStatus.Complete:
            statusBadge = <span className="rounded-lg text-white py-2 px-4 font-bold uppercase bg-gray-500"
                title="AGM ballot is complete">Complete</span>
            break;
        default:
            statusBadge = <div></div>
            break;
    }

    return (
        <div className="flex items-center">
            {ballot?.organisationLogo &&
                <div className="flex-none">
                    <img src={ballot.organisationLogo} className="mx-auto h-16 max-w-[200px] object-contain" title={ballot.organisation} alt={ballot.organisation} />
                </div>
            }
            <div className="pl-4 flex-auto">
                <span className="text-3xl block pb-2">{ballot.organisation}</span>
                <span className="text-xl">{ballot.ballotName}</span>
            </div>

            {isShowStatus &&
                <div>
                    {statusBadge}
                </div>
            }

            {children &&
                <>{children}</>
            }
        </div>
    );
}