import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { LoadingButton } from '../common/LoadingButton';
import { IUserMenu, IUserMenuItem, PortalApiRoutes, PortalRoutes } from '../../types/portalTypes';
import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarProps {
    onLoggedOut: any
    userMenu?: IUserMenu
}

export const Sidebar = ({ onLoggedOut, userMenu }: SidebarProps) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const history = useHistory();

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleLogout = async () => {
        try {
            setIsLoggingOut(true)
            const response = await axios.get(PortalApiRoutes.ACCOUNT_LOGOUT)
            if (response.status === 200) {
                // Logout successful, redirect to login page
                history.push(PortalRoutes.ACCOUNT_LOGIN)

                if (onLoggedOut) {
                    onLoggedOut()
                }

                setIsLoggingOut(false)
            }
        } catch (error) {
            console.error('Logout failed:', error)
        }
    };

    return (
        <div className={`flex flex-col bg-gray-800 text-white ${isCollapsed ? 'w-16' : 'w-64'} transition-width duration-300
        sticky top-0 h-screen`}>
            <div className="flex justify-between items-center p-4 mb-4">
                {!isCollapsed &&
                    <img src="/img/logo_white.png" alt="TrueVote" className="w-48" />
                }
                <button onClick={toggleSidebar}>
                    {isCollapsed ? <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronLeft} />}
                </button>
            </div>
            {!isCollapsed && (
                <nav className="flex-1">
                    {userMenu && userMenu.menu.map((menuItem: IUserMenuItem, index: number) => (
                        <div key={menuItem.name || index} className="relative group">
                            {menuItem.url ? (
                                menuItem.isExternal ? (
                                    <a className="block hover:bg-gray-700 px-4 py-2 text-white"
                                        href={menuItem.url}
                                        target="_blank"
                                        rel="noreferrer">{menuItem.name}</a>
                                ) : (
                                    <Link className="block hover:bg-gray-700 px-4 py-2 text-white"
                                        to={menuItem.url}>{menuItem.name}</Link>
                                )
                            ) : (
                                <span className="block hover:bg-gray-700 px-4 py-2 text-white cursor-default">{menuItem.name}</span>
                            )}
                            {menuItem.subMenu && (
                                <div className="absolute left-full top-0 hidden group-hover:block bg-gray-700 border border-gray-600 rounded shadow-lg z-10">
                                    {menuItem.subMenu.map((subMenuItem: IUserMenuItem, subIndex: number) => (
                                        subMenuItem.url ? (
                                            subMenuItem.isExternal ? (
                                                <a key={subIndex}
                                                    className="block whitespace-nowrap px-4 py-2 hover:bg-gray-600 text-white"
                                                    href={subMenuItem.url}
                                                    target="_blank"
                                                    rel="noreferrer">{subMenuItem.name}</a>
                                            ) : (
                                                <Link key={subIndex}
                                                    className="block whitespace-nowrap px-4 py-2 hover:bg-gray-600 text-white"
                                                    to={subMenuItem.url}>{subMenuItem.name}</Link>
                                            )
                                        ) : (
                                            <span key={subIndex}
                                                className="block whitespace-nowrap px-4 py-2 text-white cursor-default">{subMenuItem.name}</span>
                                        )
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>
            )}

            {!isCollapsed && (
                <div className="px-4 py-2">
                    <LoadingButton
                        btnText="Logout"
                        className="block mb-2 text-white text-left"
                        isLoading={isLoggingOut}
                        onBtnClick={handleLogout} />
                </div>
            )}
        </div>
    );
}