import React from 'react';
import { IAgmGroupResultView } from '../../types/portalTypes';

interface AgmVotedProps {
    groupResults?: IAgmGroupResultView
}

export const AgmVoted: React.FC<AgmVotedProps> = ({ groupResults }) => {
    return (
        <>
            {groupResults &&
                <div className="text-center">
                    <span className="block text-3xl mb-4">Voted</span>
                    <span className="block text-6xl">{groupResults.votersResponded} / {groupResults.totalVoters}</span>
                </div>
            }
        </>
    );
}