import React, { useMemo, useState, useEffect } from 'react'
import {
    TableInstance,
    useTable,
    useSortBy,
    UseSortByColumnProps,
    UseSortByInstanceProps,
    useGlobalFilter,
    UseGlobalFiltersInstanceProps,
    useFilters,
    UseFiltersInstanceProps,
    UseFiltersColumnProps,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    Column,
    UseTableOptions,
    Row,
    UseTableColumnProps,
    TableState,
    UseGlobalFiltersState,
    UseSortByState,
    UsePaginationOptions,
    UseSortByOptions
} from 'react-table'
import { GlobalFilter } from './GlobalFilter'
import { ColumnFilter } from './ColumnFilter'
import { TablePaging } from './TablePaging'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import '../../tailwind.css'
import '../../custom.css'

/**
 * THIS IS A COMMON COMPONENT - MAKE SURE YOUR EDITING THE COPY IN THE COMMON PROJECT!
 *
 * https://codesandbox.io/s/react-table-typescript-example-8j43u
 */
interface TableProps<T extends object> {
    fetchDataAsync: (pageSize: number, pageIndex: number, sortColumns: any[]) => Promise<any>
    columns: Array<ExtendedColumn<T>>
    onFetchData?: (data: any) => void
    isSorting?: boolean
    isFiltering?: boolean
    isGlobalSearch?: boolean
    dataId?: any
    fetchDataTrigger?: any
    isShowLoading?: boolean
}

export interface SortColumn {
    member: string
    direction: string
}

export type TextAlignType = 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';

export interface TextAlignColumnProps {
    textAlign: TextAlignType
}

export type ExtendedColumn<T extends object> = Column<T> &
    Partial<UseSortByColumnProps<T>> &
    Partial<UseFiltersColumnProps<T>> &
    Partial<UseTableColumnProps<T>> &
    Partial<TextAlignColumnProps>;

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
    UsePaginationInstanceProps<T> &
    UseSortByInstanceProps<T> &
    UseFiltersInstanceProps<T> &
    UseGlobalFiltersInstanceProps<T> & {
        state: TableState<T> & UseGlobalFiltersState<T> & UseSortByState<T> & UsePaginationState<T>;
    };

export const Table = <T extends object>({
    fetchDataAsync,
    columns,
    onFetchData,
    isSorting,
    isFiltering,
    isGlobalSearch,
    dataId,
    fetchDataTrigger,
    isShowLoading
}: TableProps<T>) => {
    const [isLoading, setLoading] = useState<boolean>(false)

    const tableColumns = useMemo(() => columns, [])

    // Paging
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    // Data
    const [data, setData] = useState([])
    const tableData = useMemo(() => data, [data])

    const fetchTableData = async () => {
        setLoading(true)

        // Get sort columns
        let sortColumns: SortColumn[] = []
        sortBy.forEach((sort) => {
            sortColumns.push({
                // .NET API capitalises property name
                member: sort.id[0].toUpperCase() + sort.id.slice(1),
                // Convert to DESC/ASC
                direction: sort.desc ? "DESC" : "ASC"
            })
        })

        const response = await fetchDataAsync(pageSize, pageIndex, sortColumns)

        if (response && response.data.isSuccessful) {
            setData(response.data.payload.results)
            setTotalRows(response.data.payload.totalResults)
            setTotalPages(Math.ceil(response.data.payload.totalResults / pageSize))
            setLoading(false)

            if (onFetchData) {
                onFetchData(response.data.payload)
            }
        }
    }

    // Default column values
    const defaultColumn = useMemo(() => {
        return {
            Filter: ColumnFilter,
            width: "auto",
            textAlign: "center"
        }
    }, [])

    interface ExtendedTableOptions<T extends object> extends UseTableOptions<T>, UsePaginationOptions<T>, UseSortByOptions<T> {
        columns: Column<T>[];
        data: T[];
        initialState?: Partial<TableState<T> & UsePaginationState<T> & UseSortByState<T>>;
    }

    const tableOptions: ExtendedTableOptions<T> = {
        columns: tableColumns,
        data: tableData,
        manualPagination: true,
        autoResetPage: false,
        manualSortBy: true,
        defaultColumn: defaultColumn,
        pageCount: totalPages,
        initialState: {
            sortBy: [],
            pageIndex: 0,
            pageSize: 50
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { globalFilter, sortBy, pageIndex, pageSize },
        setGlobalFilter
    } = useTable(
        tableOptions,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination) as TableInstanceWithHooks<T>

    // Fetch data when page, sorting or external props change
    useEffect(() => {
        fetchTableData()
    }, [pageIndex, pageSize, sortBy, dataId, fetchDataTrigger])

    return (
        <>
            <div className="mx-4 my-4">
                {isShowLoading && isLoading ? (
                    <span>Loading....</span>
                ) : (
                    <>
                        {isGlobalSearch &&
                            <GlobalFilter
                                filter={globalFilter}
                                setFilter={setGlobalFilter} />
                        }
                        <table className="min-w-full divide-y divide-gray-300" {...getTableProps()} >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map((column) => {
                                            // Cast the column to the extended type
                                            const extendedColumn = column as unknown as ExtendedColumn<T>;// Cast the column to the extended type

                                            return (
                                                <th className="py-2 px-4 uppercase text-sm align-bottom"
                                                    {...extendedColumn.getHeaderProps?.(extendedColumn.getSortByToggleProps?.() ?? {})}
                                                    style={{
                                                        width: extendedColumn.width,
                                                        textAlign: extendedColumn.textAlign
                                                    }}>
                                                    {column.render('Header')}
                                                    {isSorting && extendedColumn.isSorted && extendedColumn.isSortedDesc &&
                                                        <FontAwesomeIcon icon={faCaretDown} className="pl-2" />
                                                    }
                                                    {isSorting && extendedColumn.isSorted && !extendedColumn.isSortedDesc &&
                                                        <FontAwesomeIcon icon={faCaretUp} className="pl-2" />
                                                    }
                                                    {isFiltering &&
                                                        <div>{extendedColumn.canFilter ? extendedColumn.render?.('Filter') : ''}</div>
                                                    }
                                                </th>
                                            )
                                        })}
                                    </tr>
                                ))}

                            </thead>
                            <tbody className="divide-y divide-gray-200" {...getTableBodyProps()} >
                                {
                                    page.map((row: Row<T>) => {
                                        prepareRow(row)
                                        return (
                                            <tr className="hover:bg-gray-200" {...row.getRowProps()}>
                                                {
                                                    row.cells.map((cell) => {
                                                        // Cast the column to ExtendedColumn
                                                        const extendedColumn = cell.column as ExtendedColumn<T>;

                                                        return <td className="py-2 px-4 text-center"
                                                            {...cell.getCellProps()}
                                                            style={{
                                                                width: extendedColumn.width,
                                                                textAlign: extendedColumn.textAlign
                                                            }}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <TablePaging
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageOptions={pageOptions}
                            pageCount={pageCount}
                            gotoPage={gotoPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            setPageSize={setPageSize}
                            totalRows={totalRows} />
                    </>)}
            </div>
        </>
    )
}