import React from 'react';
import dompurify from 'dompurify';
import { Countdown } from '../common/Countdown';
import { AgmVoteStatus, IAgmGroupStatusView, IAgmGroupView } from '../../types/portalTypes';

interface AgmQuestionProps {
    question: string
    status: IAgmGroupStatusView
    groups: IAgmGroupView[]
    selectedGroup: IAgmGroupView
    onGroupSelected: any
    panelCss: string
}

export const AgmQuestion: React.FC<AgmQuestionProps> = ({ question, status, groups, selectedGroup, onGroupSelected, panelCss }) => {
    const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value)

        if (onGroupSelected) {
            onGroupSelected(selectedId)
        }
    }

    const gotoLiveQuestion = () => {
        if (status.openGroup && onGroupSelected) {
            onGroupSelected(status.openGroup.groupId)
        }
    }

    const getStatusBadge = () => {
        let baseCss = "rounded text-white py-2 px-4 font-bold uppercase"

        // Use open group details
        if (status.openGroup?.groupId === selectedGroup.groupId) {
            if (status.status === AgmVoteStatus.AgmGroupPaused) {
                return (
                    <span className={`${baseCss} bg-blue-700`}>Paused</span>
                )
            }
            else if (status.openGroup.closeTime) {
                return (
                    <Countdown
                        className={`${baseCss} bg-blue-700`}
                        targetDateUtc={status.openGroup.closeTime}
                        isPaused={false}
                        isDoomsdayFormat={false} />
                )
            }
            else if (status.status === AgmVoteStatus.AgmGroupOpen) {
                return (
                    <span className={`${baseCss} bg-green-700`}>Open</span>
                )
            }
        }
        // Check for withdrawn
        else if (selectedGroup.isWithdrawn) {
            return (
                <span className={`${baseCss} bg-red-700`}>Withdrawn</span>
            )
        }
        // Check if not open yet
        else if (!selectedGroup.openTime) {
            return (
                <span className={`${baseCss} bg-amber-700`}>Not Open</span>
            )
        }

        // Default state
        return (
            <span className={`${baseCss} bg-gray-700`}>Closed</span>
        )
    }

    return (
        <>
            <div className={`${panelCss} flex items-center mb-4`}>
                <span className="text-3xl flex-grow" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question.replace("\n", "</br>")) }} />
                {getStatusBadge()}
            </div>
            {groups &&
                <div className={`${panelCss} flex items-center`} >
                    <span className="mr-2 flex-none text-xl">Select group:</span>
                    <select
                        className="inline flex-grow max-w-none"
                        value={selectedGroup?.groupId ?? ''}
                        onChange={handleGroupChange}>
                        {groups.map(group => (
                            <option key={group.groupId} value={group.groupId}>{group.isWithdrawn ? group.groupName + " (withdrawn)" : group.groupName}</option>
                        ))}
                    </select>
                    {status.openGroup && selectedGroup && status.openGroup.groupId !== selectedGroup.groupId &&
                        < button
                            className="btn ml-4 flex-none"
                            onClick={gotoLiveQuestion}>Goto Live</button>
                    }
                </div>
            }
        </>
    );
}